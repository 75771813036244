import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/Home.vue'
import BaseMap from './components/BaseMap.vue'

const routes = [
    {
        name: 'Home',
        component: HomePage,
        path: '/'
    },
    {
        name: 'BaseMap',
        component: BaseMap,
        path: '/mapa'
    }
]

const router = createRouter(
    {
        history: createWebHistory(),
        routes
    }
)

export default router
