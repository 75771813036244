<template>
  <div>
    <div id="mapContainer" />
  </div>

  <div class="logos" v-if=true>
    <div>
      <img src="banner.png">
      <p>&copy; <a href="https://leafletjs.com/">Leaflet</a> | &copy; <a
          href="http://www.openstreetmap.org/copyright">OpenStreetMap</a></p>
    </div>
  </div>

  <div class="marker-form" v-if="showMarkerForm">
    <div>
      <h2>Ingresa tus datos</h2>
      <p>Esto quedará almacenado en el mapa</p>
      <form @submit.prevent>
        <label>Nombre del lugar</label><br />
        <input type="text" placeholder="Añade un nombre" v-model="nombre" required />
        <label for="categoria">Tipo de lugar</label><br />
        <select name="categoria" id="categoria" v-model="categoria" required>
          <option value="Lugar con conflicto o amenazado">Lugar con conflicto o amenazado</option>
          <option value="Lugar valioso social y/o medioambientalmente">Lugar valioso social y/o medioambientalmente
          </option>
          <option value="Lugar con iniciativas ecológicas o sociales">Lugar con iniciativas ecológicas o sociales
          </option>
        </select>
        <label>Descríbelo</label><br />
        <input type="text" placeholder="¿En qué consiste?" id="descriptionHolder" v-model="descripcion" />
        <label>¿Quién(es) participa(n)?</label><br />
        <input type="text" placeholder="¿Quién(es) participa(n)?" id="descriptionHolder" v-model="antiguedad" />
        <div class="button">
          <button type="button" @click="addMarker">Agregar</button>
          <button type="button" @click="deleteMarker">Cancelar</button>
        </div>

      </form>
    </div>
  </div>
  <!-- <LogIn /> -->
  <!-- <PersistentMenu v-if="showPersistenMenu" /> -->
  <!-- <StartMenu /> -->
  <!-- <MarkerForm v-if="showMarkerForm" /> -->
</template>

<script>
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
// import { v4 as uuidv4 } from 'uuid'
// import LogIn from './LogIn.vue'
// import StartMenu from './StartMenu.vue'
// import PersistentMenu from './PersistentMenu.vue'
// import MarkerForm from './MarkerForm'
// import axios from 'axios'
// let map = null

// Crea layers
var conflicto = L.layerGroup();
var valor = L.layerGroup();
var iniciativas = L.layerGroup();

var streets = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
});

var overlays = {
  "Lugar con conflicto o amenazado &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;": conflicto,
  "Lugar valioso social y/o medioambientalmente": valor,
  "Lugar con iniciativas ecológicas o sociales &nbsp; &nbsp; &nbsp;": iniciativas
};

var newIcon = L.icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [32, 32],
  iconAnchor: [20, 41],
  popupAnchor: [-4, -50],
  shadowSize: [50, 50]
});

export default {
  name: 'BaseMap',
  components: {
    // LogIn,
    // StartMenu,
    // PersistentMenu
    // MarkerForm
  },


  data() {
    return {
      map: null,
      markers: [],
      newMarker: null,
      total: 0,
      showMarkerForm: false,
      showMenuForm: false,
      showSignUpForm: false,
      markerLat: null,
      markerLng: null,
      iconUrl: null,
      showPersistenMenu: true,
      nombre: '',
      categoria: '',
      descripcion: '',
      recurrencia: 'none',
      antiguedad: ''

    }
  },

  mounted() {
    this.createMap()
    this.getData()
  },

  methods: {
    createMap() {
      this.map = L.map('mapContainer', {
        center: [-37.008584, -72.962265],
        zoom: 11,
        zoomAnimation: false,
        zoomControl: false,
        layers: [streets, conflicto, valor, iniciativas]
      })
      this.map.on('contextmenu', this.handleEvent);
      L.control.layers(null, overlays, { position: 'topleft', collapsed: false }).addTo(this.map);
    },

    async getData() {
      //  let categoria = "Otros"
      await fetch('https://www.paisajenahuelbutanorte.cl:3000/markers/')
        // fetch('http://127.0.0.1:3000/markers')
        .then(res => res.json())
        .then(data => {
          this.markers = data
          this.markers.map((marker) => {
            console.log(marker)
            var thisIcon = new L.Icon({
              iconUrl: marker.iconUrl,
              shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
              iconSize: [32, 32],
              iconAnchor: [20, 41],
              popupAnchor: [-4, -50],
              shadowSize: [50, 50]
            });

            let layer // = seguridad
            // console.log(marker.categoria)
            switch (marker.category) {
              case "Lugar con conflicto o amenazado":
                layer = conflicto;
                // this.iconUrl = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png'
                break;
              case "Lugar valioso social y/o medioambientalmente":
                layer = valor;
                // this.iconUrl = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png'
                break;
              case "Lugar con iniciativas ecológicas o sociales":
                layer = iniciativas;
                // this.iconUrl = 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png'
                break;
            }

            let myPopup = '<b>' + marker.name + '</b> <br />'
              + marker.category + '<br /><br />'
              + '<b>Descripción</b><br />' + marker.description + '<br /><br />'
              // + '<b>Recurrencia</b><br />' + marker.recurrence + '<br /><br />'
              + '<b>¿Quién(es) participa(n)?</b><br />' + marker.antiqueness
            L.marker([marker.lat, marker.lng], { icon: thisIcon, title: marker.id })
              .bindPopup(myPopup)
              .addTo(layer)
          })
        })
        .catch(err => console.log(err.message + "!!"))
    },

    handleEvent(e) {
      this.markerLat = e.latlng.lat
      this.markerLng = e.latlng.lng
      this.showMarkerForm = true
      if (!this.newMarker) {
        console.log("Latlng: " + e.latlng)
        this.newMarker = L.marker(e.latlng, { icon: newIcon }).addTo(this.map);
        this.newMarker.bindPopup("¡Tu nuevo marcador!", { closeButton: false, closeOnClick: false, keepInView: true })
          .openPopup();
      } else {
        alert("Completa el formulario o presiona CANCELAR")
      }
    },

    async addMarker() {
      let layer
      // this.iconUrl = "interrogation.png"
      switch (this.categoria) {
        case "":
          console.log("Sin categoría")
          break
        case "Lugar con conflicto o amenazado":
          layer = conflicto;
          this.iconUrl = 'zona-conflicto.png'
          break;
        case "Lugar valioso social y/o medioambientalmente":
          layer = valor;
          this.iconUrl = 'lugar-importante.png'
          break;
        case "Lugar con iniciativas ecológicas o sociales":
          layer = iniciativas;
          this.iconUrl = 'iniciativas-socioambientales.png'
          break;
      }

      if (this.nombre && this.categoria) {
        //let uuid = uuidv4()
        let url = 'https://www.paisajenahuelbutanorte.cl:3000/markers/'
        // let url = 'https://143.110.226.64:3000/markers'
        // let url = 'http://127.0.0.1:3000/markers'
        if (!this.descripcion) {
          this.descripcion = "No declarada"
        }
        if (!this.antiguedad) {
          this.antiguedad = "No declarada"
        }
        let data = {
          //id: uuid,
          name: this.nombre,
          lat: this.markerLat,
          lng: this.markerLng,
          category: this.categoria,
          description: this.descripcion,
          recurrence: this.recurrencia,
          antiqueness: this.antiguedad,
          iconUrl: this.iconUrl
        }

        await fetch(url, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*"
          }
        }).then(response => console.log(response.ok))

        var thisIcon = new L.Icon({
          iconUrl: this.iconUrl,
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [32, 32],
          iconAnchor: [20, 41],
          popupAnchor: [-4, -50],
          shadowSize: [50, 50]
        });

        let myPopup = '<b>' + this.nombre + '</b> <br />'
          + this.categoria + '<br /><br />'
          + '<b>Descripción</b><br />' + this.descripcion + '<br /><br />'
          + '<b>Participantes</b><br />' + this.antiguedad + '<br /><br />'
        // + '<b>¿Existe hace > 5 años?</b><br />' + this.antiguedad
        L.marker([this.markerLat, this.markerLng], { icon: thisIcon, title: this.id })
          .bindPopup(myPopup).openPopup()
          .addTo(layer)

        this.showMarkerForm = false
        this.map.removeLayer(this.newMarker)
        this.newMarker = null

        this.nombre = ''
        this.categoria = ''
        this.descripcion = ''
        this.recurrencia = 'none'
        this.antiguedad = ''
      } else {
        alert("Debes ingresar al menos el nombre y el tipo de lugar.")
      }
    },

    deleteMarker() {
      this.showMarkerForm = false
      this.map.removeLayer(this.newMarker)
      this.newMarker = null
      this.nombre = ''
      this.categoria = ''
      this.descripcion = ''
      this.recurrencia = 'none'
      this.antiguedad = ''
    },

    setLayers() {
      this.showLayer = true
    }

  }

}
</script>

<style scoped>
.menuForm {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(250, 250, 250, 0.9);
  width: 20%;
  height: 60%;
  border-radius: 20px;
  color: black;
}

.menuButtons button {
  margin-top: 20px;
  margin-bottom: 3px;
  width: 120px;
  height: 40px;
}

.menuButtons {
  font-size: 12px;
}

#descriptionHolder {
  width: 200px;
  height: 50px;
  padding-left: 20px;
  display: block;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  border: 1px solid skyblue;
}

.marker-form {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* text-align: left; */
  padding-left: 20px;
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(250, 250, 250, 0.8);
  width: 280px;
  height: 450px;
  border-radius: 20px;
  color: black;
}

.marker-form input {
  width: 200px;
  height: 20px;
  padding-left: 20px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  border: 1px solid skyblue;
}

.marker-form select {
  width: 225px;
  height: 20px;
  /* padding-left: 20px; */
  display: block;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 10px;
  border: 1px solid skyblue;
}

.marker-form button {
  display: block;
  width: 150px;
  height: 30px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  border: 1px solid skyblue;
  background: skyblue;
  color: #fff;
}

.marker-form h2 {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: -10px;
}

.marker-form p {
  font-size: 14px;
  margin-bottom: 12px;
}

/* .logos {
  margin: auto;
  text-align: center;
  display: flex;
  vertical-align: middle; */
/* align-items: center; */
/* align-items: center; */
/* text-align: left; */
/* padding-left: 20px;
  position: fixed;
  z-index: 1000;
  top: 100px;
  left: 50px;
  transform: translate(-50%, -50%); */
/* background: rgba(250, 250, 250, 0.8); */
/* width: 10px;
  height: 10px;
  border-radius: 20px; */
/* color: black; */
/* } */

.logos {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  /* padding-left: 20px; */
  position: fixed;
  z-index: 1000;
  bottom: 3px;
  right: 3px;
  /* transform: translate(-50%, -50%); */
  background: rgba(250, 250, 250, 0.8);
  width: 280px;
  height: 280px;
  border-radius: 20px;
  color: black;
}

.logos img {
  width: 280px;
  height: 250px;
}

.logos p {
  font-size: 12px;
  margin-top: 0px;
}
</style>
